import './App.css';
import { getTimeZones, rawTimeZones, timeZonesNames, abbreviations } from "@vvo/tzdb";
import { useEffect, useState } from 'react';

function App() {
  let [uniqueOffsets, setUniqueOffsets] = useState([]);
  let [offsetNames, setOffsetNames] = useState({});
  let [closest, setClosest] = useState({});

  useEffect(() => {
    // Calculate timezone stuff
    let tzList = getTimeZones();
    let uniqueOffsets = [];
    let offsetNames = {};
    tzList.forEach((tz) => {
      if (!uniqueOffsets.includes(tz.currentTimeOffsetInMinutes)) {
        uniqueOffsets.push(tz.currentTimeOffsetInMinutes);
        offsetNames[tz.currentTimeOffsetInMinutes] = [tz];
      } else {
        offsetNames[tz.currentTimeOffsetInMinutes].push(tz);
      }
    })
    setUniqueOffsets(uniqueOffsets);
    setOffsetNames(offsetNames);

    // Create array of Date objects for each timezone
    let dateObjects = [];
    uniqueOffsets.forEach((offset) => {
      // let date = new Date();
      // // get utc time
      // let utc = date.getTime() - (date.getTimezoneOffset() * 60000);
      // // offset is in minutes
      // let newDate = new Date(utc + (offset * 60000));
      // dateObjects.push({ dateObject: newDate, hours: newDate.getHours(), minutes: newDate.getMinutes() });
      console.log(offsetNames[offset][0].name)
      let options = {
        timeZone: offsetNames[offset][0].name,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      }
      let formatter = new Intl.DateTimeFormat([], options);
      
      dateObjects.push({offset, localParts: formatter.formatToParts(new Date())})
    })

    // Now with this list, find the one with the local time closest to 12:00
    // Now with this list, find the one with the local time closest to 12:00
    let closest = dateObjects.reduce((prev, curr) => {
      let prevHours = parseInt(prev?.localParts?.find(v => v.type === "hour").value);
      let prevMinutes = parseInt(prev?.localParts?.find(v => v.type === "minute").value);
      let currHours = parseInt(curr?.localParts?.find(v => v.type === "hour").value);
      let currMinutes = parseInt(curr?.localParts?.find(v => v.type === "minute").value);
      
      let prevDiff = Math.abs((prevHours * 60) + prevMinutes - 12 * 60);
      let currDiff = Math.abs((currHours * 60) + currMinutes - 12 * 60);

      return prevDiff < currDiff ? prev : curr;
    });

    setClosest({closest, timezones: offsetNames[closest.offset]})
    console.log(dateObjects)
    console.log(closest)
  }, [])

  return (
    <div className="App">
      It is Probably Day in the following places:<br />
      {closest.timezones?.map((tz, i) => {
        return <span key={i}>{tz.mainCities[0]}, {tz.countryName} ({tz.name})<br /></span>
      })}
      The time there is {closest.closest?.localParts?.find(v => v.type === "hour").value}:{closest.closest?.localParts?.find(v => v.type === "minute").value}
    </div>
  );
}

export default App;
